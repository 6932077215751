import RecommendationService from '@/services/recommendation.service';

export default {
	methods: {
		async viewRecommendationsPortal() {
			this.recommendationPortalLoading = true;

			const link = await RecommendationService.getRecommendationPortalLink(this.leadId, true);
			const recommendationPortalLink = link.data;
			const id = (new Date()).getTime();
			window.open(recommendationPortalLink , '_blank', id);
			this.recommendationPortalLoading = false;
		},

		async searchVenues(leadId, eventId, searchFilters = {}, correctedInvalidDataFromUser = {}) {
			let params = {
				lead_id: leadId,
				event_id: eventId
			};

			if (Object.keys(searchFilters).length) {
				params.filters = JSON.stringify(searchFilters);
			}

            if (Object.keys(correctedInvalidDataFromUser).length) {
                params.corrected_invalid_data = JSON.stringify(correctedInvalidDataFromUser);
            }

			return await this.$store.dispatch("venues/karenSearch", params);
		},

        updateKarenListingPageData(data) {
			if (data.venues) {
				this.$emit('update:venues', data.venues);
			}

			if (data.searchCriteria) {
				this.$emit('update:searchCriteria', data.searchCriteria);
				this.$emit('update:currentSearchCriteria', data.searchCriteria);
			}

			if (data.defaultSearchCriteria) {
				this.$emit('update:defaultSearchCriteria', data.defaultSearchCriteria);
			}

            if (data.selectableLocations) {
				this.$emit('update:selectableLocations', data.selectableLocations);
			}

			if (data.venueStyles) {
				this.$emit('update:venueStyles', data.venueStyles);
			}
        },

        updateData(result) {
            if (result.venues) {
                this.venues = result.venues;
            }

            if (result.searchCriteria) {
                this.searchCriteria = result.searchCriteria;
                this.currentSearchCriteria = result.searchCriteria;
            }

            if (result.defaultSearchCriteria) {
                this.defaultSearchCriteria = result.defaultSearchCriteria;
            }

            if (result.selectableLocations) {
                this.cities = result.selectableLocations;
            }

            if (result.venueStyles) {
                this.venueStyles = result.venueStyles;
            }
        }
	}
};