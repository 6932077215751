<template>
	<div id="send_recommendation_widget" v-loading="loading">
		<div class="row">
			<div class="col-8">
				<h6 class="heading-small text-muted mb-4">Queued Venues</h6>
			</div>
			<div class="col-4 text-right">
				<a
					@click.prevent="showEmailTemplate"
					class="btn btn-sm btn-primary"
					id="send_venue_recommendation"
					href="#"
				>
					Preview
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12" id="queued_venues">
				<ul
					class="list-group list-group-flush list my--3"
				>
					<li
						v-for="venue in queued_recommendations"
						:key="`${venue.id || venue.venue_id}_${venue.room_id || venue.space}_queued_recommendations`"
						class="list-group-item px-0"
					>
						<div class="row align-items-center">
							<div class="col-auto">
								<span class="avatar">
									<img alt="Image placeholder" :src="venue.thumbnail || venue.image_url" />
								</span>
							</div>
							<div class="col">
								<h5 class="mb-0">{{ venue.name || venue.venue_name }}</h5>
								<small class="text-muted">{{ venue.space || venue.room_name }}</small>
							</div>
							<div class="mr-3" @click="removeFromQueue(venue)">
								<badge
									rounded tag="a" class="btn-remove" href="javascript:"
									type="danger">
									✕ Remove 
								</badge>
							</div>
						</div>
					</li>
				</ul>
				<p class="text-center mt-5" v-if="queued_recommendations.length == 0">Please Add Some Venues</p>
			</div>
		</div>
		<el-dialog
			title="Email Text Template"
			width="50%"
			:visible.sync="recommendationTextEditDialogVisible"
			v-if="recommendationTextEditDialogVisible"
			:close-on-click-modal="false"
			:append-to-body="true"
		>
			<base-input
				type="text"
				label="Email Subject"
				placeholder="Email Subject"
				v-model="emailTemplateSubject"
			>
			</base-input>
			<base-input label="Email Body">
				<html-editor name="editor" :value="emailTemplate" @input="editEmailText"></html-editor>
			</base-input>
			<span slot="footer" class="dialog-footer">
				<button class="btn edit" @click="recommendationTextEditDialogVisible = false">
					Cancel
				</button>
				<button class="btn btn-primary edit submit" id="send_venue_recommendation_final" @click.stop="sendRecommendation">
					Send
				</button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import RecommendationService from "@/services/recommendation.service";
import EnquiryService from "@/services/enquiry.service";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Loading, Dialog } from 'element-ui';
import recommendationMixin from "@/helpers/recommendationMixin";

export default {
	props:{
		leadEventId: {
			required: true
		},
		recommendations: {
			type: Array
		}
	},
	data() {
		return {
			recommendationTextEditDialogVisible: false,
			emailTemplateText: '',
			emailTemplateSubject: '',
			portalLoginLink: '',
			loading: true
		};
	},
	components: {
		[Dialog.name]: Dialog,
		[HtmlEditor.name]: HtmlEditor,
		HtmlEditor
	},
	mixins: [ recommendationMixin ],
	computed: {
		isCountryAU() {
			return this.leadInfo.country?.toUpperCase() === 'AU';
		},

		isCountryUK() {
			return this.leadInfo.country?.toUpperCase() === 'UK';
		},

		auWeddingTemplateMessages() {
			return this.line(`Congratulations on your engagement, I just tried to give you a quick call!`, true) + 
				this.line(`Have you had a chance to look over the brochure you downloaded for ${this.leadInfo.last_enquired_venue.name}? I would love to organise a venue tour for you!`, true) +
				this.portalLinkMessage(EnquiryService.EVENT_TYPE_WEDDING) +
				this.line(`I work with a range of gorgeous venues and I'm always happy to chat through the many options that'll work within the scope of your wedding vision and budget.`);
		},

		auCorporateTemplateMessages() {
			return this.line(`Thank you for your event enquiry. I just tried to give you a quick call!`, true) +
				this.line(`Have you had a chance to look over the brochure you downloaded for ${this.leadInfo.last_enquired_venue.name}? I would love to put together a quote, and organise a venue tour for you!`) +
				this.portalLinkMessage(EnquiryService.EVENT_TYPE_CORPORATE) +
				this.line(`I work with a range of amazing venues and I'm always happy to chat through the many options that'll work within the scope of your event vision and budget.`);
		},

		emailTemplate() {
			return this.isCountryAU ? this.auEmailTemplate : this.ukEmailTemplate;
		},

		auEmailTemplate() {
			return this.line(`Hey ${this.leadInfo.first_name},`, true) +
				(
				this.leadInfo.is_corporate_event ? this.auCorporateTemplateMessages : this.auWeddingTemplateMessages
				) +
				this.line(`If you have any questions ` + (this.leadInfo?.is_corporate_event ? 'about the venues I work with' : '') + `, please don't hesitate to reach out.`) +
				this.line(`I look forward to hearing from you soon!`);
		},

		ukEmailTemplate() {
			return this.line(`Hi ${this.leadInfo.first_name},`, true) +
				this.line(`Hope you’re well?  I just tried to give you a quick call!`, true) +
				this.line(`Have you had a chance to look over the brochure you downloaded for ${this.leadInfo.last_enquired_venue.name}?`, true) +
				this.line(`Based on the style of the venues you have selected, I've identified some additional spaces below that I think you might/would also like. Let me know your thoughts and/or if you’d like to view any venues so I can arrange viewings for you :)`, true) +
				this.line(`Our services are completely free so there are no costs involved. Take a look at some of our reviews from our past clients!`) +
				this.line(`I look forward to hearing from you soon!`);
		},

		emailSubjectMessage() {
			return 'Your personalised venue recommendations!';
		},

		queued_recommendations() {
			return this.recommendations;
		},

		leadInfo() {
			return this.$store.getters["leadEvent/getLeadEventsById"](this.leadEventId);
		}
	},
	async mounted() {
		await this.$store.dispatch("leadEvent/getSingle", this.leadEventId);
		this.loading = false;
	},
	methods: {
		portalLinkMessage(type) {
			if (this.isCountryAU && this.portalLoginLink) {

				return this.line('I have also added a list of curated venues below, and into your personal Venue Crew dashboard! You can login <a href="' + this.portalLoginLink + '">here</a> and let me know which ones you love, request venue tours, or ask for more recommendations if I’ve missed the mark :)');
			}

			// Retain the old message if the portal login link is not found or if the lead country is not Australia (AU).
			if (type === EnquiryService.EVENT_TYPE_WEDDING) {

				return this.line(`Based on the style of the venues you seem to lean toward, I've also selected some additional spaces below that I think would be perfect for your wedding. I'd love to hear your thoughts and organise site inspections for you when you're ready.`);
			}

			return this.line(`Based on the style of the venues you seem to lean toward, I've also selected some additional spaces below that I think would be perfect for your upcoming event. I'd love to hear your thoughts on these options as well.`);
		},

		line(text, extraLine) {
			extraLine = extraLine || false;
			return `
				<p style="font-family: 'Nunito', Arial, Sans-serif; font-size: 15px; letter-spacing: 0.4px; word-break: break-word; font-weight: 200;">
					${text}
				</p> `
				+ ((extraLine) ? '<p></p>' : '');
		},

		removeFromQueue(venue) {
			const index = this.queued_recommendations.indexOf(venue);
			if (index > -1) {
				this.queued_recommendations.splice(index, 1); // Remove the venue from the array
			}
			this.$emit('update:recommendations', this.queued_recommendations);
		},

		showEmailTemplate() {
			if (!this.queued_recommendations.length) {
				return;
			}

			this.recommendationTextEditDialogVisible = true;
			this.emailTemplateSubject = this.emailSubjectMessage;
			this.getCompatibleVenuesObject();
			this.$emit('previewed');
		},

		async sendRecommendation() {
			if (!this.queued_recommendations.length) {
				return;
			}

			const loading = this.loadingState();
			await this.$store.dispatch("recommendations/sendRecommendation", {
				leadEventId: this.leadEventId,
				venues: this.queued_recommendations,
				template: this.emailTemplateText ? this.emailTemplateText : this.emailTemplate,
				subject: this.emailTemplateSubject ? this.emailTemplateSubject : this.emailSubjectMessage,
				portalLink: this.portalLoginLink,
			});
			this.$emit('update:recommendations', []);
			this.recommendationTextEditDialogVisible = false;

			await this.$store.dispatch('recommendations/getRecommendations', this.leadEventId);

			let results = await this.searchVenues(this.$route.params.id, this.$route.params.eventId);
			if (results?.venues?.length) {
				this.$emit('update:venues', results.venues);
			}

			loading.close();
		},

		editEmailText(text) {
			this.emailTemplateText = text;
		},

		loadingState() {
			return Loading.service({
				lock: true,
				background: 'rgba(0, 0, 0, 0.7)'
			});
		},

		async getPortalLink(leadId){
			const link = await RecommendationService.getRecommendationPortalLink(leadId);

			const basicUrlPattern = new RegExp('^(https?:\\/\\/)?');
			if (link.data && !!basicUrlPattern.test(link.data)) {
				this.portalLoginLink = link.data;
			}
		},

		getCompatibleVenuesObject() {
			let venuesObject = [];
			this.queued_recommendations.forEach(function(venue) {
				if (! venue.id) {
					venue.id = venue.venue_id;
				}

				if (! venue.space) {
					venue.space = venue.room_name
				}

				venuesObject.push(venue);
			});

			return venuesObject;
		}
	},
	watch: {
		leadInfo: function () {
			this.getPortalLink(this.leadInfo.lead_id);
		}
	}
};
</script>
<style>
.ql-editor {
	word-break: normal;
}
</style>